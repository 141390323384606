import React from "react"
import PropTypes from "prop-types"

import "../css/layout.css"
import "../css/typography.css"

const Layout = ({ children }) => {
  return (
    <>
      <div id="home"
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          minHeight: `100vh`,
        }}
      >
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
